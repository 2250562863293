import { CartTile } from './CartTile'
import { CartHeader } from './CartHeader'
import { CartPaymentTile } from './CartPaymentTile'
import { CartFooter } from './CartFooter'
import React, { useState, useContext, useEffect, useMemo } from 'react'
import { useAppContext } from '@/core/App/AppContext'
import clsx from 'clsx'
import './cart-wrapper.scss'
import useCartContext from './useCartContext'
import { Accordian } from '../Accordian'
import { CheckoutGlowPoint } from '../Checkout/CheckoutGlowPoint'
import useAuthContext from '../Contexts/AuthContext'
import { CheckoutCoupon } from '../Checkout/CheckoutCoupon'
import { CashBackText } from '../Checkout'
import { OfferText } from '../Header'
import { cartViewEvent } from 'src/services/gtm'
import we from 'src/services/webengage'
import { checkFreeShipping } from '../Checkout/checkFreeShipping'
import Link from '@/core/Link'



export default function CartWrapper() {
  const [{ mitter }] = useAppContext()
  const [cartState, , { isCartContainsBogoProduct }] = useCartContext()
  const { items, hasOnlyGiftCard } = cartState
  const [auth] = useAuthContext()
  const [open, setOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(null)

  useEffect(() => {
    const setState = () => {
      open
        ? document.body.classList.remove('mini-cart')
        : document.body.classList.add('mini-cart')
      setOpen(!open)
    }
    open == false && document.body.classList.remove('mini-cart') // this is when user click on backdrop

    if (open == true) {
      cartViewEvent(cartState)
      we.event('Cart Viewed', {
        'Product Details': cartState?.items,
        'Product Name': `${cartState.items.map((i) => (i.product.name))}`,
        'Total Amount': cartState?.prices?.grand_total?.value,
        'No Of Products': cartState?.total_quantity
      })
    }
    mitter.on('MiniCartToggle', setState)
    return () => {
      mitter.off('MiniCartToggle', setState)
    }
  }, [open])

  return (
    <div className={clsx('cart-wrapper')}>
      {open && <div className="backdrop" onClick={() => setOpen(false)}></div>}
      <div
        className={clsx('cart-sections', {
          open,
        })}
      >
        <CartHeader />
        <div className='offer-text'>{'Get 4 Free mini products on order above 599/-'}</div>
        {/* <pre>{JSON.stringify(cartState, null, 2)}</pre> */}
        {!auth.isAuthenticated && (
          <div className="signup-text">
            {/* Sign up & earn instantly 15 moha: Glow Points ( only applicable for
            the new users ) */}
            Use code "BOGO" to avail Buy 1 Get 1 Free Offer on all products ( Excluding Discounted Products & Kits )
          </div>
        )}
        {items && items?.length > 0 ? (
          <>
            <div className="cart-content-wrapper">
              <CartTile />
              <CartPaymentTile />
              {auth.isAuthenticated && !hasOnlyGiftCard && (
                <div className="cart-glow-point">
                  <Accordian
                    className="glow-points"
                    index={0}
                    selectedIndex={selectedIndex}
                    onClick={setSelectedIndex}
                    title={'moha: glow points'}
                  >
                    <CheckoutGlowPoint />
                  </Accordian>
                </div>
              )}
              {!hasOnlyGiftCard ? (
                <div className="cart-coupon">
                  <CheckoutCoupon isCartOpen={true} />
                </div>
              ) : null}
              <CashBackText />
            </div>
            <CartFooter cart={cartState} />
          </>
        ) : null}
      </div>
    </div>
  )
}
